<template>
	<div class="qr-entries-table pb-3">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="id"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<span class="subtitle-2 font-weight-light grey--text mr-2">Выберите дом для отображения устройств </span>
								<v-autocomplete
									v-model="filter.house_ids"
									class="border-all"
									solo
									dense
									flat
									multiple
									hide-details
									item-text="apartment_address"
									item-value="id"
									:items="houses"
									:filter="addressFilter"
									@change="updateQuery"
								>
								</v-autocomplete>
							<!-- Сброс фильтров -->
							<!-- <v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn> -->
						</div>
					</template>
				</v-data-footer>
			</template>
			<!-- <template v-slot:[`body.prepend`]>
				<tr>
					<td>
						<v-text-field
							v-model="filter.name"
							placeholder="Поиск по названию"
							class="border-all mr-5"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<v-combobox
							v-model="filter.house_ids"
							placeholder="Адрес"
							class="text-caption border-all"
							solo
							multiple
							item-text="apartment_address"
							item-value="_id"
							:return-object="false"
							:items="houses"
							:filter="addressFilter"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0 && item.length > 1">
									{{ currentHouses(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.house_ids.length - 1 }})
								</span>
							</template>
						</v-combobox>
					</td>
						<td>
							<v-autocomplete
								v-model="filter.type_id"
								class="border-all"
								solo
								dense
								flat
								hide-details
								item-text="name"
								item-value="id"
								:items="[{ name: 'Все', id: null }, ...devices_types]"
								@change="updateQuery"
								:disabled="loading"
							>
							</v-autocomplete>
					</td>
				</tr>
			</template> -->
			<template v-slot:[`item.houses`]="{ item }">
				<td>
					<p class="mt-2 mb-2" v-for="(item, i) in item.houses" :key="i">{{item.apartment_address}}</p>
				</td>
			</template>
		</v-data-table>
		<v-dialog v-model="dialog" persistent max-width="900px">
			<v-card>
				<!-- Header -->
				<v-card-title class="d-flex justify-space-between border-bottom">
					<span class="subtitle-1">Список домов</span>
					<v-btn color="dark" @click="dialog = false" icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<!-- Body -->
				<v-card-text>
					<v-container>
						<v-form ref="form">
							<v-row v-for="(data, i) in device.houses" :key="i">
								<!-- Название въезда -->
								<v-col cols="12" sm="6">
									<span class="subtitle-2 font-weight-light">
										Адрес
									</span>
									<p class="mt-2">{{ data.apartment_address }}</p>
								</v-col>
								<v-col cols="12" sm="4">
									<span class="subtitle-2 font-weight-light">
										Управляющая компания
									</span>
									<p class="mt-2">{{ data.ksk.name || '---' }}</p>
									<!-- <v-select
										v-model="data.company_id"
										item-text="name"
										item-value="_id"
										:items="kskList"
										flat
										solo
										disabled
										append-icon=""
										hide-details
									> -->

									<!-- </v-select> -->
								</v-col>
								<v-col cols="12" sm="1">
									<v-btn
										color="red white--text"
										class="d-block mt-6"
										depressed
										small
										@click="showModalConfirm = true; item = data"
									>
									<v-icon small>mdi-close</v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<!-- Alert -->
								<v-col cols="12" class="py-0">
									<v-alert
										v-if="alert"
										class="mb-0"
										:type="alert.color"
										:color="alert.color"
										outlined
										dense
									>
										{{ alert.message }}
									</v-alert>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- Подтверждение удаления -->
		<ModalConfirm
			v-if="showModalConfirm"
			title="Удаление устройства"
			:text="`Удалить ${item.apartment_address}?`"
			@close-modal="deleteDeviceHouse"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalConfirm from "@/components/app/ModalConfirm";
export default {
	name: "QrNextDevices",
	components: {
		ModalConfirm,
	},
	data() {
		return {
			dialog: false,
			toEdit: null,
			alert: null,
			firstFetch: false,
			items: [],
			device: {
				houses: [],
				id: ''
			},
			item: {
				apartment_address: '',
				id: ''
			},
			filter: {
				house_ids: [],
			},
			filtersQuery: {
				"house_ids": "house_ids",
			},
			headers: [
				{ text: "ID", value: "id", sortable: false },
				{ text: "Multipass ID", value: "multipass_device_id", sortable: false },
				{ text: "Название", value: "name", sortable: false },
				{ text: "Адреса", value: "houses", sortable: false },
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageStop: 0,
				pageCount: 0,
				itemsLength: 0,
			},
			loading: false,
			showModalCreate: false,
			showModalEdit: false,
			showModalConfirm: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			role: "auth/GET_USER_ROLE_VUEX",
			houses: "ksk/GET_NEWQR_EXIST",
			kskList: "catalog/GET_KSK_LIST",
		}),
	},

	watch: {
		options: {
			handler() {
				if(this.firstFetch) {
					const query = { ...this.$route.query, page: this.options.page };
					this.$router.replace({ query });
					this.loadData(query);
				}
			},
			deep: true,
		},
	},

	methods: {
		handleRowClick(e) {
			console.log('e', e)
			this.device = e
			this.dialog = true
		},
		async deleteDeviceHouse(isConfirmed) {
			this.showModalConfirm = false;

			if (!isConfirmed) return;

			try {
				this.loading = true;

				const res = await this.$api.ksk.delete_device_house(this.device.id, this.item.id);

				this.alert = {
					color: "success",
					message: res.success || "Дом отвязан с устройства",
				};
				const query = { ...this.$route.query, page: this.options.page };	
				if (query.house_ids && typeof query.house_ids === 'string') {
					query.house_ids = [query.house_ids];
				}
				await this.loadData(query);
				this.dialog = false
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
			} finally {
				this.loading = false;
				this.alert = null
			}
		},
		async loadData(params) {
			if(!params.hasOwnProperty('house_ids') || !params.house_ids.length) {
				this.options = {
					page: +this.$route.query.page || 1,
					itemsPerPage: 10,
					pageStart: 1,
					pageStop: 0,
					pageCount: 0,
					itemsLength: 0,
				}
				this.firstFetch = false
				return this.items = []
			}
			this.loading = true;
			params.short = false
			return this.$api.ksk
				.load_devices(params)
				.then(res => {
					console.log('res', res)
					this.items = res.records;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
					this.firstFetch = true
				});
		},

		async updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			await this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		console.log('houses', this.houses)
		this.filter.house_ids = this.houses.map(item => item.id);
		if(this.filter.house_ids.length) {
			console.log('this.filter.house_ids.length', this.filter.house_ids.length)
			const query = { ...this.$route.query, page: this.options.page , house_ids: this.filter.house_ids};
			this.$router.replace({ query })
			this.loadData(query);
		}
	},
};
</script>

<style lang="scss" scoped>
.whitelist-table {
	height: 100vh;
}
</style>
